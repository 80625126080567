
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { Layout } from '../components/Common/';
import usePostsEn from '../graphql/queries/usePostsEn';
import usePostsEs from '../graphql/queries/usePostsEs';
import { PostPreview } from '../components/Blog/PostPreview/PostPreview';
import { SetLocationContext } from '../providers/LocationProvider';

const Blog = (props) => {
  const { locale } = useIntl();
  const postsEn = usePostsEn();
  const postsEs = usePostsEs();
  const setLocation  = useContext(SetLocationContext);
  setLocation('Blog');

  function selectLanguagePost() {
    if (locale === 'en') {
      return postsEn.map((post, index) => (
        <PostPreview 
          key={post.slug}
          date={post.date}
          title={post.title}
          image={post.image}
          fluid={post.fluid}
          paragraph={post.content}
          link={`/blog/${post.slug}`}
          alt={post.alt}
          badges={post.tags}
        />  
      ))
    }
    return postsEs.map((post, index) => (
      <PostPreview 
        key={post.slug}
        date={post.date}
        title={post.title}
        image={post.image}
        fluid={post.fluid}
        paragraph={post.content}
        link={`/blog/${post.slug}`}
        alt={post.alt}
        badges={post.tags}
      />  
    ))
  }
  return ( 
    <Layout> 
      <section className="pt-32 md:pt-48 pb-1 mx-auto w-full md:w-4/5">
        <div className="w-11/12 md:w-10/12 lg:w-4/6 mx-auto">
          <h1 className="text-center  text-6xl mb-6">
            {<FormattedMessage id="articles" />}
          </h1>
          {selectLanguagePost()}
        </div>
      </section>
    </Layout>
  );
}

export default Blog;