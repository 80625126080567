import React, { useContext } from 'react';
import { Link } from 'gatsby-plugin-intl';
import Image from 'gatsby-image';
import { ThemeContext } from '../../../providers/ThemeProvider';
import { Badge } from '../../Common/';
import './PostPreview.scss';

export const PostPreview = (props) => {
  const theme = useContext(ThemeContext);
  const seletctedTheme = theme === 'dark' ? 'lighter-dark-theme' : 'light-theme';
  return ( 
    <Link to={props.link} >
      <div className={`post-preview flex flex-col mb-12 ${seletctedTheme}`}>
        { props.fluid ?
            <Image
              fluid={props.fluid}
              alt={props.title}
              className="rounded-lg image-height"
            />
            :
            <img 
              className="h-auto w-full rounded-lg"
              src={props.image} 
              alt={props.title}
            />
        }
        <article className="p-4 md:p-6 flex flex-col justify-around">
          <h1 className="text-3xl  md:text-5xl font-bold">
            {props.title}
          </h1>
          <p className="article-text text-justify three-dots text-2xl md:text-3xl">
            { `${props.paragraph.substring(0, 300)}...` }
          </p>
          <div className="my-4 flex justify-between items-center">
            <div>
              {props.badges.length !== 0 ? 
                <div>
                  <Badge
                    className={props.badges[2]}
                    text={props.badges[0]}
                  />
                   <Badge
                    className={`${props.badges[3]} ml-8`}
                    text={props.badges[1]}
                  />
                </div>
                : 
                <Badge
                  className="bg-black"
                  text="no tag"
                />
              }
            </div>
            <span className="text-2xl">
            <strong>{props.date}</strong>  
            </span>
          </div>
        </article>
      </div>
    </Link>
  );
}